import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/enviroment';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ICurrencyData,
  ICurrencyEditRequest,
  ICurrencyRequest,
  ICurrencyResponse,
} from '../interfaces/currency';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  public allCurrencies: BehaviorSubject<ICurrencyResponse> =
    new BehaviorSubject({} as ICurrencyResponse);

  public currencies = [
    { currency: 'USD' },
    { currency: 'CAD' },
    { currency: 'EUR' },
    { currency: 'AED' },
    { currency: 'AFN' },
    { currency: 'ALL' },
    { currency: 'AMD' },
    { currency: 'ARS' },
    { currency: 'AUD' },
    { currency: 'AZN' },
    { currency: 'BAM' },
    { currency: 'BDT' },
    { currency: 'BGN' },
    { currency: 'BHD' },
    { currency: 'BIF' },
    { currency: 'BND' },
    { currency: 'BOB' },
    { currency: 'BRL' },
    { currency: 'BWP' },
    { currency: 'BYN' },
    { currency: 'BZD' },
    { currency: 'CDF' },
    { currency: 'CHF' },
    { currency: 'CLP' },
    { currency: 'CNY' },
    { currency: 'COP' },
    { currency: 'CRC' },
    { currency: 'CVE' },
    { currency: 'CZK' },
    { currency: 'DJF' },
    { currency: 'DKK' },
    { currency: 'DOP' },
    { currency: 'DZD' },
    { currency: 'EEK' },
    { currency: 'EGP' },
    { currency: 'ERN' },
    { currency: 'ETB' },
    { currency: 'GBP' },
    { currency: 'GEL' },
    { currency: 'GHS' },
    { currency: 'GNF' },
    { currency: 'GTQ' },
    { currency: 'HKD' },
    { currency: 'HNL' },
    { currency: 'HRK' },
    { currency: 'HUF' },
    { currency: 'IDR' },
    { currency: 'ILS' },
    { currency: 'INR' },
    { currency: 'IQD' },
    { currency: 'IRR' },
    { currency: 'ISK' },
    { currency: 'JMD' },
    { currency: 'JOD' },
    { currency: 'JPY' },
    { currency: 'KES' },
    { currency: 'KHR' },
    { currency: 'KMF' },
    { currency: 'KRW' },
    { currency: 'KWD' },
    { currency: 'KZT' },
    { currency: 'LBP' },
    { currency: 'LKR' },
    { currency: 'LTL' },
    { currency: 'LVL' },
    { currency: 'LYD' },
    { currency: 'MAD' },
    { currency: 'MDL' },
    { currency: 'MGA' },
    { currency: 'MKD' },
    { currency: 'MMK' },
    { currency: 'MOP' },
    { currency: 'MUR' },
    { currency: 'MXN' },
    { currency: 'MYR' },
    { currency: 'MZN' },
    { currency: 'NAD' },
    { currency: 'NGN' },
    { currency: 'NIO' },
    { currency: 'NOK' },
    { currency: 'NPR' },
    { currency: 'NZD' },
    { currency: 'OMR' },
    { currency: 'PAB' },
    { currency: 'PEN' },
    { currency: 'PHP' },
    { currency: 'PKR' },
    { currency: 'PLN' },
    { currency: 'PYG' },
    { currency: 'QAR' },
    { currency: 'RON' },
    { currency: 'RSD' },
    { currency: 'RUB' },
    { currency: 'RWF' },
    { currency: 'SAR' },
    { currency: 'SDG' },
    { currency: 'SEK' },
    { currency: 'SGD' },
    { currency: 'SOS' },
    { currency: 'SYP' },
    { currency: 'THB' },
    { currency: 'TND' },
    { currency: 'TOP' },
    { currency: 'TRY' },
    { currency: 'TTD' },
    { currency: 'TWD' },
    { currency: 'TZS' },
    { currency: 'UAH' },
    { currency: 'UGX' },
    { currency: 'UYU' },
    { currency: 'UZS' },
    { currency: 'VEF' },
    { currency: 'VND' },
    { currency: 'XAF' },
    { currency: 'XOF' },
    { currency: 'YER' },
    { currency: 'ZAR' },
    { currency: 'ZMK' },
    { currency: 'ZWL' },
  ];

  // used to store object from all gateways to use in edit comp
  public editData!: ICurrencyEditRequest;

  private _HttpClient = inject(HttpClient);

  getCurrenciesWithPagination( page: number, limit?: number, name?: string, code?: string, status?: boolean ): Observable<ICurrencyResponse> {
    let optionalParams = [];
    if (name) optionalParams.push(`&name=${name}`);
    if (code) optionalParams.push(`&code=${code}`);
    if (limit) optionalParams.push(`&limit=${limit}`);
    if (page) optionalParams.push(`&page=${page}`);
    if (status == true || status == false) optionalParams.push(`&isActive=${status}`);
    const url = `${environment.apiUrl}/v1/currency/get?${optionalParams.join('').split(',').toString()}`;
    return this._HttpClient.get<ICurrencyResponse>(url);
  }

  // Create Currency
  addCurrency(formData: ICurrencyRequest): Observable<ICurrencyResponse> {
    return this._HttpClient.post<ICurrencyResponse>(
      `${environment.apiUrl}/v1/currency/create`,
      formData
    );
  }

  // update Currency
  getCurrency(id: string): Observable<ICurrencyEditRequest> {
    return this._HttpClient.get<ICurrencyEditRequest>(`${environment.apiUrl}/v1/currency/get/${id}`);
  }

  // update Currency
  updateCurrency(formData: ICurrencyEditRequest): Observable<ICurrencyResponse> {
    return this._HttpClient.put<ICurrencyResponse>(`${environment.apiUrl}/v1/currency/update/${formData._id}`, formData);
  }

  // Delete Currency
  deleteCurrency(id: string): Observable<any> {
    return this._HttpClient.delete(
      `${environment.apiUrl}/v1/currency/delete/${id}`
    );
  }
}
